import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Layout from "../component/Layout/Layout";
import Hero from "../component/Hero/Hero";
import BlogPosts from "../component/Posts/BlogPosts";
import StickyBox from "../component/StickyBox/StickyBox";
import Button from "../component/Button/Button";
import config from "../../config.json";
import "../assets/styles/article-page.sass";
import CompareWidget from "../component/CompareWidget/CompareWidget";
import {useStaticQuery, graphql} from "gatsby";
import Seo from "../component/Seo/Seo";
import RocznyLimitWplat from "../assets/images/roczny_limit_wplat_na_ikze.jpg"

const Limits: React.FC = () => {
  const {wpPage} = useStaticQuery(graphql`
    query LimitsSeo {
      wpPage(slug: {eq: "limit-wplat-na-ikze"}) {
        seo {
          metaDesc
          title
          focuskw
        }
        opisStrony {
          opis
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.metaDesc}
        pathname="/limit-wplat-na-ikze/"
      />
      <Hero
        heading={`Limit wpłat na IKZE 2024. Sprawdź 🕵️`}
        image={
          <StaticImage
            src="../assets/images/photo/hero-limit.jpg"
            alt={`Limit wpłat na IKZE 2024`}
            title={`Limit wpłat na IKZE 2024`}
            height={600}
            width={828}
            quality={90}
          />
        }
        paragraph={wpPage.opisStrony.opis}
      />
      <div className="container mw-1400">
        <div className="row">
          <div className="col-xl-8 article">
            <h2 className="first">Jaki jest limit wpłat na IKZE w 2024 r. i jakie daje korzyści?</h2>
            <div className="line"></div>
            <p className="text">
              IKZE wciąż nie cieszy się wśród Polaków taką popularnością, na jaką zasługuje. Jest to poniekąd zrozumiałe – instytucje finansowe nie promują tego rozwiązania, bo nie jest dla nich opłacalne. 
            </p>
            <p className="text">
              Istotne korzyści z indywidualnych kont zabezpieczenia emerytalnego czerpią za to konsumenci. Za sprawą niewygórowanych na pierwszy rzut oka limitów mogłoby się wydawać, że tak nie jest. Przyjrzymy się temu!
            </p>
            <div className="table mt-4">
              <table cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th></th>
                    <th>IKE kwota wpłat</th>
                    <th>Limity wpłat IKZE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Roczny limit wpłat na IKE i IKZE w 2024 roku</td>
                    <td>
                      <span className="big">23 472 zł</span>
                    </td>
                    <td>
                      <span className="big">9 388,80 zł</span>samozatrudnieni: 14 083,20 zł
                    </td>
                  </tr>
                  <tr>
                    <td>Korzyści podatkowe podczas oszczędzania na koncie emerytalnym</td>
                    <td>
                      <span className="big">X</span>
                    </td>
                    <td>
                      wpłaty odliczysz w deklaracji PIT <strong>nawet do 3 004 zł w 2024 r.</strong><br/>
                      samozatrudnieni wyższy limit wpłat, nawet do 4 507 zł w 2024 r.
                    </td>
                  </tr>
                  <tr>
                    <td>Korzyści podatkowe przy wypłacie (na koniec oszczędzania)</td>
                    <td>
                      <p>1. brak 19% podatku od zysków kapitałowych</p>
                      2. zwolnienie z podatku dochodowego od osób fizycznych (PIT)
                    </td>
                    <td>
                      <p>1. brak 19% podatku od dochodów kapitałowych</p>
                      2. 10% zryczałtowany podatek dochodowy
                    </td>
                  </tr>
                  <tr>
                    <td>Podatek od spadku i darowizn</td>
                    <td>
                      <span className="big">nie</span>
                    </td>
                    <td>
                      <span className="big">nie</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Minimalna pierwsza wpłata</td>
                    <td>
                      <span className="big">ok. 100 zł</span>
                    </td>
                    <td>
                      <span className="big">ok. 100 zł</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Minimalna kolejna wpłata</td>
                    <td>
                      <span className="big">50 zł</span>
                    </td>
                    <td>
                      <span className="big">50 zł</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Opłata za założenie konta emerytalnego</td>
                    <td>
                      <span className="big">0 zł</span>
                    </td>
                    <td>
                      <span className="big">0 zł</span>
                    </td>
                  </tr>
                  <tr className="bottom">
                    <td colSpan={3} className="center-child">
                      <Button title="Porównaj oferty IKZE" internalLink={config.CALC_URL} width="fit-content" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <small>Tabela 1. Kwoty wpłat na IKZE i IKE w 2024 r. Opracowanie własne na podstawie dostępnych danych. Aktualizacja marzec 2024.</small>
            </div>
            <h2>Dlaczego oszczędzaniu na IKZE towarzyszy limit wpłat?</h2>
            <div className="line"></div>
            <p className="text">
              Odpowiedź na to najbardziej oczywiste z nasuwających się pytań będzie dla nas jasna, jeśli zadamy sobie inne: <strong>czemu ma służyć IKZE</strong>? – Oszczędzaniu na emeryturę rzecz jasna. Nie gromadzeniu bądź inwestowaniu środków na inne cele przy jednoczesnym unikaniu podatków. Zabezpieczenie własnej przyszłości wobec słabości systemu emerytalnego jest właśnie tym, co wedle ustawodawcy zasługuje na stosowne ulgi.
            </p>
            <h2>Wysokość kwoty wpłat nie jest bez znaczenia!</h2>
            <div className="line"></div>
            <p className="text">
              Szczęśliwie od lat zwiększa się przeciętna różnica pomiędzy dochodem rozporządzalnym a wydatkiem na jedną osobę w polskich gospodarstwach domowych. Według danych GUS w 2020 roku te drugie „zjadały” pierwsze już tylko w 63%. W roku 2015 było to 78,7%, a w 2010 – 83,7%. Do połowy lat 2000., nie mówiąc o równie ciężkich latach 90., gdy uderzyły w nas skutki transformacji, wskaźnik ten przekraczał 90%.
            </p>
            <p className="text">
              Różnica ta, przynajmniej do tej pory, rosła więc coraz szybciej. Ile jednak wynosi ona w konkretnych kwotach? Wszyscy dobrze wiemy, że wciąż nie jesteśmy zbyt zamożnym krajem: w 2020 r. roku było to nieco ponad 700 zł miesięcznie. Ile z tego jesteśmy gotowi odłożyć na emeryturę?
            </p>
            <h2>Czy limity wpłat na IKZE są wystarczające?</h2>
            <div className="line"></div>
            <p className="text">
              <strong>Powiedzmy sobie wprost: maksymalny limit rocznych wpłat na IKZE wynoszący w 2024 roku 9 388,8 zł dla statystycznego Kowalskiego jest aż nadto wystarczający. Dla bogatego inwestora będzie rozczarowujący, ale... tak właśnie ma być. To nie on w myśl tej propozycji zasługuje na ulgi podatkowe.</strong>
            </p>
            <p className="text">
              IKZE nie da się wykorzystać do obniżenia podatków nawet doraźnie dla ograniczonych kwot. Bo przecież jeśli wykorzystamy roczny limit i zechcemy dokonać wypłaty w następnym roku, to powiększymy sobie dochód uwzględniany do rozliczenia w kolejnym zeznaniu podatkowym.
            </p>
            <p className="text">
              Druga istotna okoliczność to fakt, że emerytura – rzecz jasna, jeśli pomyślimy o niej odpowiednio wcześnie – stanowi cel nader odległy, toteż nawet umiarkowanie wysokie wpłaty są w stanie zamienić się w poważne oszczędności.
            </p>
            <div className="table mt-4 table-container">
              <p className="table-header">Limit wpłat na IKZE w latach 2017-2024</p>
              <table cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th className="text-center">Rok</th>
                    <th className="text-center">Limit IKZE</th>
                    <th className="text-center">Limit dla samozatrudnionych</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2024</td>
                    <td>9 388,80 zł</td>
                    <td>14 083,20 zł</td>
                  </tr>
                  <tr>
                    <td>2023</td>
                    <td>8 322,00 zł</td>
                    <td>12 483,00 zł</td>
                  </tr>
                  <tr>
                    <td>2022</td>
                    <td>7 106,40 zł</td>
                    <td>10 659,20 zł</td>
                  </tr>
                  <tr>
                    <td>2021</td>
                    <td>6 310,80 zł</td>
                    <td>9 466,20 zł</td>
                  </tr>
                  <tr>
                    <td>2020</td>
                    <td>6 272,40 zł</td>
                    <td>x</td>
                  </tr>
                  <tr>
                    <td>2019</td>
                    <td>5 718,00 zł</td>
                    <td>x</td>
                  </tr>
                  <tr>
                    <td>2018</td>
                    <td>5 331,60 zł</td>
                    <td>x</td>
                  </tr>
                  <tr>
                    <td>2017</td>
                    <td>5 115,60 zł</td>
                    <td>x</td>
                  </tr>
                </tbody>
              </table>
              <small>Tabela 2. Limit wpłat na IKZE w latach 2017-2024</small>
            </div>

            <h2>Ile wynosi roczny limit wpłat IKZE i z czego się bierze?</h2>
            <div className="line"></div>
            <p className="text">
              Już od momentu wprowadzenia IKZE wysokość wpłat była limitowana, z początku było to jednak rozwiązanie bardziej złożone. Skromny limit wynosił wówczas 4% podstawy wymiaru składki na ubezpieczenie emerytalne za rok poprzedni, przy czym inaczej traktowano najmniej zarabiających. Każdy miał więc swój własny limit wpłat na IKZE.
            </p>
            <h2>Od czego zależy limit wpłat IKZE?</h2>
            <div className="line"></div>
            <p className="text">
              W 2013 roku dokonano jednak zmiany i wprowadzono proste rozwiązanie obowiązujące do dzisiaj: <strong>limit wpłat na IKZE stanowi 1,2-krotność prognozowanego na dany rok przeciętnego prognozowanego wynagrodzenia miesięcznego</strong>. To oznacza, że dla (prawie) każdego jest on taki sam i z roku na rok rośnie.
            </p>
            <img src={RocznyLimitWplat} alt="Roczny limit wpłat na IKZE" className="img-w-100" />
            <p className="text">
              Oprócz tego od 2021 roku istnieją <strong>dwa limity</strong> – pojawił się osobny dla samozatrudnionych (prowadzących pozarolniczą działalność, w rozumieniu art. 8 ust. 6 ustawy z dnia 13 października 1998 r. o systemie ubezpieczeń społecznych). Ten wynosi z kolei <strong>1,8-krotności prognozowanego przeciętnego wynagrodzenia</strong>, a więc na rok 2024 r. jest to 14 083,2 zł
            </p>
            <h2>Jak kwoty wpłat na IKZE pracują na naszą przyszłość?</h2>
            <div className="line"></div>
            <p className="text">
              Jak nietrudno policzyć, przez ostatnie 5 lat limit wpłat na IKZE zwiększył się o prawie jedną trzecią. Kto zaczął więc oszczędzać w 2016 roku, w 2021 r. mógł mieć na koncie ponad 33 i pół tys. zł, nie licząc odsetek z kapitału. Do tego mógł otrzymać zwroty podatkowe wynoszące w sumie 4641 zł, a po 2022 r. roku 5714. Warto było!
            </p>
            <p className="text">
              W korzystaniu z narzędzi przeznaczonych do oszczędzania na emeryturę to niekoniecznie dodatkowy zysk jest najważniejszy. <strong>Kluczowa jest systematyczność i wytrwałość</strong> w dążeniu do długookresowego celu. IKZE jest rozwiązaniem, które znakomicie temu sprzyja – pochopna przedwczesna likwidacja rachunku zwyczajnie się nie opłaca.
            </p>
            <p className="text">
            Z drugiej strony, nie da się na nim również niefrasobliwie umieścić nagle bardzo dużych pieniędzy, które mogłyby być potrzebne na inne cele. Rachunki IKZE działają w ten sposób, że nadpłaty po prostu wrócą na nasze konto, natomiast wpłaty dokonywane w ramach ustawowego limitu mają zapewnić nam w przyszłości spokojną i godną emeryturę. Po ustalonym czasie środki z IKZE zostaną wypłacone na wniosek oszczędzającego.
            </p>
          </div>
          <div className="col-xl-4">
            <StickyBox>
              <CompareWidget isNarrow={true} />
            </StickyBox>
          </div>
        </div>
        <div className="row">
          <BlogPosts />
        </div>
      </div>
    </Layout>
  );
};

export default Limits;
